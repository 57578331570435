import {
  Button,
  HStack,
  ListItem,
  Text,
  ToastId,
  useBreakpointValue,
} from '@chakra-ui/react'
import useProductStatuses from '@modules/app/hooks/useProductStatuses'
import {
  CartLineItemFragment,
  getCartItemPrices,
  useCart,
} from '@modules/commercetools'
import { useComposable } from '@modules/oriuminc/base'
import { ProductKey } from '@modules/spoonflower/models/productKey'
import { useToast } from '@modules/ui'
import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import { FormatNumberOptions, useIntl } from 'react-intl'
import { IMAGE_PLACEHOLDER } from '../../../../app'
import { useDebounce } from '../../../../app/hooks/useDebounce'
import useDesignSalabilities from '../../../../app/hooks/useDesignSalabilities'
import useUserPreferences from '../../../../app/hooks/useUserPreferences'
import useWhoAmI from '../../../../app/hooks/useWhoAmI'
import { getLegacyPDP } from '../../../utils'
import { CartProductCard, CartProductCardProps } from '../../CartProductCard'
import getItemName from '../helpers/getItemName'
import useLineItemAttributes, {
  VariantAttribute,
} from './../hooks/useLineItemAttributes'

export default function CartItem({
  item,
  shouldLinkToPDP,
  isEditable,
}: {
  item: CartLineItemFragment
  shouldLinkToPDP: boolean
  isEditable: boolean
}) {
  const { cart, addCartItem, updateCartItem, deleteCartItem } = useCart()
  const { currency, locale } = useComposable()
  const {
    preferences: { currency: preferredCurrency },
  } = useUserPreferences()
  const lineItemAttributes = useLineItemAttributes(
    cart?.lineItems ?? [],
    locale
  )
  const {
    data: { designSalabilities },
  } = useDesignSalabilities(cart?.lineItems, cart?.id)
  const {
    data: { productStatuses },
    isFetched,
  } = useProductStatuses(cart?.lineItems, cart?.id)
  const formatNumberOptions: FormatNumberOptions = {
    currency: preferredCurrency ?? currency,
    style: 'currency',
  }
  const intl = useIntl()
  const { data: user } = useWhoAmI()
  const userLocale = user?.preferences?.locale ?? 'en'
  const productCartSize = useBreakpointValue({ base: 'xs', md: 'md', lg: 'lg' })
  const maxQuantity = 999
  const router = useRouter()
  const toast = useToast()
  const pillowInsertToastRef = useRef<ToastId>()
  const undoToastRef = useRef<ToastId>()
  const { netPrice, total } = getCartItemPrices(item)
  const { customFields, details, variantAttributes } =
    lineItemAttributes[item.id]
  const detailsList = [...details]
  const _productSlug = variantAttributes[VariantAttribute.LegacyPath]?.value

  // Some line items may not contain designs, and for those we assume they are salable.
  const designIsSalable = designSalabilities[item.id] ?? true
  const name = getItemName(item, variantAttributes, customFields)
  const pdpUrl = getLegacyPDP(
    _productSlug!,
    customFields.designID?.value!,
    userLocale
  )
  const statuses = productStatuses[item.variant?.sku!]
  const productIsSalable = isFetched
    ? statuses &&
      statuses['product-status'] === 'Active' &&
      statuses['variant-status'] === 'Active'
    : true
  const lineItemIsSalable = productIsSalable && designIsSalable
  const quantity = item.quantity || 0
  const regularPrice = (netPrice / 100) * quantity
  const totalPrice = total / 100
  const totalDiscountedAmount = regularPrice - totalPrice

  const handleQuantityChange = useDebounce(
    async (valueAsNumber: number, item: any) => {
      if (valueAsNumber >= 0) {
        await updateCartItem.mutate({
          quantity: valueAsNumber,
          itemId: item.id,
        })

        if (valueAsNumber === 0) {
          launchUndoToast(item)
        }
      }
    },
    500
  )

  const launchPillowInsertToast = (item: CartLineItemFragment) => {
    pillowInsertToastRef.current = toast({
      description: intl.formatMessage({ id: 'cart.toast.pillowInserts' }),
      duration: 10000,
      isClosable: false,
      status: 'info',
    })
  }

  const launchUndoToast = (item: CartLineItemFragment) => {
    undoToastRef.current = toast({
      description: (
        <HStack justifyContent="space-between">
          <Text>{intl.formatMessage({ id: 'cart.toast.removed' })}</Text>

          <Button
            colorScheme="black"
            onClick={() => {
              addCartItem.mutate({
                productId: item.productId,
                quantity: item.quantity,
                variantId: item.variant?.id,
                custom: {
                  type: {
                    typeId: 'type',
                    key: 'lineItemCustomDesignType',
                  },
                  fields: item.custom?.customFieldsRaw?.map(
                    ({ name, value }) => ({
                      name,
                      value: JSON.stringify(value),
                    })
                  ),
                },
              })

              undoToastRef.current && toast.close(undoToastRef.current)
              pillowInsertToastRef.current &&
                toast.close(pillowInsertToastRef.current)
            }}
            variant="solid"
          >
            {intl.formatMessage({ id: 'cart.toast.undo' })}
          </Button>
        </HStack>
      ),
      isClosable: false,
      variant: 'cart-action',
    })
  }

  if (!isEditable) {
    // Adding item quantity to list on review step
    detailsList?.push({
      name: intl.formatMessage({ id: 'cart.item.quantity' }),
      value: item.quantity,
    })
  }

  useEffect(() => {
    if (item.quantity > maxQuantity) {
      item.quantity = maxQuantity
      handleQuantityChange(maxQuantity, item)
    }
    if (item.productKey === ProductKey.Subscriptions && item.quantity > 1) {
      item.quantity = 1
      handleQuantityChange(1, item)
    }
  }, [item.quantity])

  return (
    <ListItem key={item.id}>
      <CartProductCard
        key={item.id}
        columns={4}
        size={productCartSize as CartProductCardProps['size']}
        image={{
          src:
            customFields.designImageURL?.value ??
            item.variant?.images?.[0]?.url ??
            IMAGE_PLACEHOLDER,
          alt: item.name ?? '',
          onClickImage: () => router.push(pdpUrl),
        }}
        name={name}
        artist={customFields.artistName?.value}
        details={detailsList}
        quantity={item.quantity}
        regularPrice={intl.formatNumber(regularPrice, formatNumberOptions)}
        totalDiscountedAmount={
          totalDiscountedAmount
            ? intl.formatNumber(totalDiscountedAmount, formatNumberOptions)
            : undefined
        }
        salePrice={
          totalDiscountedAmount
            ? intl.formatNumber(totalPrice, formatNumberOptions)
            : undefined
        }
        onRemove={() => {
          deleteCartItem.mutate({ itemId: item.id })

          launchUndoToast(item)

          /**
           * If a user removes a throw pillow, we want to warn them
           * that they might still have an insert in their cart.
           *
           * This a temporary until we create a "cover + insert" bundle.
           */
          if (item.productKey === ProductKey.ThrowPillow) {
            launchPillowInsertToast(item)
          }
        }}
        onChangeQuantity={(valueAsString, valueAsNumber) => {
          handleQuantityChange(valueAsNumber, item)
        }}
        isLoading={updateCartItem.isLoading || deleteCartItem.isLoading}
        pdpUrl={shouldLinkToPDP ? pdpUrl : undefined}
        isEditable={isEditable}
        format={variantAttributes.format?.value}
        isSalable={lineItemIsSalable}
        productKey={item.productKey as ProductKey}
      />
    </ListItem>
  )
}
