import {
  Box,
  Button,
  Card,
  Center,
  Grid,
  GridItem,
  HStack,
  Image,
  ListItem,
  Text,
  UseNumberInputProps,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { SPOONFLOWER_BASE_URL } from '@modules/app'
import { Link } from '@modules/app/components/Link'
import { QuantityPicker } from '@modules/app/components/QuantityPicker'
import { ProductKey } from '@modules/spoonflower/models/productKey'
import { useIntl } from 'react-intl'
import { getGridConfig } from './helpers'
import { CartProductCardBaseProps } from './types'

export interface CartProductCardProps extends CartProductCardBaseProps {
  productKey?: ProductKey
  totalPrice?: string
  onChangeQuantity?: UseNumberInputProps['onChange']
  isEditable?: boolean
}

export const CartProductCard = (props: CartProductCardProps) => {
  const intl = useIntl()

  const {
    image,
    artist,
    name,
    details = [],
    regularPrice,
    salePrice,
    totalDiscountedAmount,
    quantity,
    pdpUrl,
    format,
    productKey,
  } = props
  const {
    columns = 4,
    isLoading,
    onRemove,
    onAddToWishlist,
    onChangeQuantity,
    size = 'lg',
    isEditable = true,
    isSalable,
  } = props

  const quantityLabel = intl.formatMessage({ id: 'cart.item.quantity' })
  const itemPriceLabel = intl.formatMessage({ id: 'cart.item.price' })
  const grid = getGridConfig({ quantityLabel, itemPriceLabel })
  const gridSettings = grid[columns]?.[size]
  const asValue = useBreakpointValue({ xs: Card, sm: Box })
  const isFillAYard = productKey === ProductKey.FillAYard
  const isSubscription = productKey === ProductKey.Subscriptions
  const isColorMap = productKey === ProductKey.ColorMap
  const artistProfileUrl = `${SPOONFLOWER_BASE_URL}/profiles/${artist}`

  if (!gridSettings) {
    return null
  }

  const getGridItemDisplayValue = (area: string, defaultDisplay?: string) => {
    const shouldHide = !gridSettings.templateAreas.includes(area)
    return shouldHide ? 'none' : defaultDisplay
  }

  return (
    <Box
      as={asValue}
      borderRadius="none"
      padding={{ base: '6', xl: '0' }}
      boxShadow={{ base: 'base', xl: 'none' }}
      backgroundColor={{ base: 'warm-white', xl: 'transparent' }}
    >
      <Grid {...gridSettings}>
        <GridItem area="img" position="relative">
          <Center>
            {!isSalable && (
              <Center
                backgroundColor="white-opacity.800"
                height="100%"
                padding={{ base: '1', lg: '4' }}
                position="absolute"
                width="100%"
                zIndex="overlay"
              >
                <Text
                  align="center"
                  color="red.500"
                  fontSize={{ base: 'md', lg: 'lg' }}
                  fontWeight="bold"
                >
                  {intl.formatMessage({ id: 'cart.item.noLongerAvailable' })}
                </Text>
              </Center>
            )}

            {pdpUrl && isSalable ? (
              <Link href={pdpUrl}>
                <Image alt={image?.alt} fit="contain" src={image?.src} />
              </Link>
            ) : (
              <Image alt={image?.alt} fit="contain" src={image?.src} />
            )}

            {format && (
              <Text
                as="span"
                bottom="1"
                layerStyle="cartItemFormat"
                position="absolute"
              >
                {format}
              </Text>
            )}
          </Center>
        </GridItem>
        <GridItem
          area="nameAndArtist"
          colSpan={{ base: 2, lg: 1 }}
          minWidth="0"
        >
          <Box textStyle="ellipsis">
            {pdpUrl && isSalable ? (
              <Link href={pdpUrl}>
                <Text as="span">{name}</Text>
              </Link>
            ) : (
              <Text as="span">{name}</Text>
            )}
          </Box>

          {artist && (
            <Box textStyle="ellipsis">
              <Text as="span" color="tertiary-text">
                {intl.formatMessage({ id: 'cart.item.by' })}
                <Link href={artistProfileUrl}>
                  <Text
                    as="span"
                    color="tertiary-text"
                    textDecoration="underline"
                  >
                    {artist}
                  </Text>
                </Link>
              </Text>
            </Box>
          )}
        </GridItem>
        <GridItem area="price" layerStyle="primary">
          <HStack>
            {totalDiscountedAmount && (
              <Text color="earth.600" mb={2}>
                {intl.formatMessage(
                  { id: 'cart.item.saving' },
                  { totalDiscountedAmount }
                )}
              </Text>
            )}
          </HStack>
          <HStack spacing="3">
            {salePrice && <Text fontWeight="bold">{salePrice}</Text>}
            <Text
              color={salePrice ? 'gray.500' : undefined}
              fontWeight={salePrice ? undefined : 'bold'}
              textDecoration={salePrice ? 'line-through' : undefined}
            >
              {regularPrice}
            </Text>
          </HStack>
        </GridItem>
        <GridItem
          area="details"
          display={getGridItemDisplayValue('details')}
          layerStyle="tertiary"
        >
          <VStack spacing={{ base: '1' }}>
            {details.map(({ name, value }, index) => (
              <ListItem key={index} maxW={'full'} width={'full'}>
                <Text as="span" key={index}>
                  <Text as="span" fontWeight="semibold">
                    {name && `${name}: `}
                  </Text>
                  {value}
                </Text>
              </ListItem>
            ))}
          </VStack>
        </GridItem>
        <GridItem
          area="quantity"
          display={getGridItemDisplayValue('quantity', 'flex')}
          marginBottom={{ base: '6', sm: '0' }}
        >
          <QuantityPicker
            hideLabel
            isDisabled={
              !isSalable || isFillAYard || isSubscription || isColorMap
            }
            isLoading={isLoading}
            onChange={onChangeQuantity}
            value={quantity}
            isEditable={isEditable}
            max={999}
          />
        </GridItem>
        <GridItem
          area={{ base: 'buttons', sm: 'auto' }}
          colSpan={{ base: 2, sm: 1 }}
          display={getGridItemDisplayValue('buttons')}
          marginBottom={{ base: '-6', sm: '0' }}
          marginX={{ base: '-6', sm: '0' }}
        >
          <HStack
            flexDirection={{ base: 'row-reverse', sm: 'row' }}
            spacing={{ base: '0', sm: '2', lg: '6' }}
          >
            {onAddToWishlist && isEditable && (
              <Button
                aria-label={`${intl.formatMessage({
                  id: 'action.saveForLater',
                })} ${name}`}
                borderRightWidth={{
                  base: '0px !important',
                  sm: '1px !important',
                }}
                size={{ base: 'sm', sm: 'md', lg: 'md' }}
                onClick={onAddToWishlist}
                type="button"
                width={{ base: '100%', sm: 'auto' }}
                variant={{ base: 'cart-product-card-base', sm: 'outline' }}
              >
                {intl.formatMessage({ id: 'action.saveForLater' })}
              </Button>
            )}
            {onRemove && isEditable && (
              <Button
                aria-label={`${intl.formatMessage({
                  id: 'action.remove',
                })} ${name}`}
                borderLeftWidth={{
                  base: '0px !important',
                  sm: '1px !important',
                }}
                flexShrink={{ base: '2', sm: '1' }}
                marginRight={{ base: '-1px !important', sm: '0 !important' }}
                size={{ base: 'sm', sm: 'md', lg: 'md' }}
                onClick={onRemove}
                type="button"
                width={{ base: '100%', sm: 'auto' }}
                variant={{ base: 'cart-product-card-base', sm: 'outline' }}
              >
                {intl.formatMessage({ id: 'action.remove' })}
              </Button>
            )}
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  )
}
